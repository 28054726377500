export default class Taxation {
    constructor() {
        this.id = "";
        this.identificacao = "";
        this.codigo = "";
        this.codigoTributacao = "";
        this.cnae = "";
        this.tipoTributacao = { content: 'Tributável Dentro do Município', id: '6' };
        this.exigibilidade = { content: 'Exigível', id: '1' };
        this.aliquota = 0;
        this.discriminacao = "";
    }
    update(data) {

        this.id = data.id;
        this.identificacao = data.identificacao;
        this.codigo = data.codigo;
        this.codigoTributacao = data.codigoTributacao;
        this.cnae = data.cnae;

        this.tipoTributacao = data.tipoTributacao;
        this.exigibilidade = data.exigibilidade;

        this.aliquota = data.aliquota;
        this.discriminacao = data.discriminacao;
    }
}


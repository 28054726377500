<template>
    <div>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="6" xl="2">
                <SelectStatic title="Simples Nacional" :required="true" :initialValue="configCompany.simplesNacional"
                    :data="optionsSimplesNacional" v-model="configCompany.simplesNacional" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="4">
                <SelectStatic title="Regime Tributário" :required="true" :initialValue="configCompany.regimeTributario"
                    :data="optionsRegimeTributario" v-model="configCompany.regimeTributario" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="4">
                <SelectStatic title="Regime Tributário Especial" :required="true"
                    :initialValue="configCompany.regimeTributarioEspecial" :data="optionsRegimeTributarioEspecial"
                    v-model="configCompany.regimeTributarioEspecial" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="2">
                <SelectStatic title="Incentivador Cultural" :required="true"
                    :initialValue="configCompany.incentivadorCultural" :data="optionsIncentivadorCultural"
                    v-model="configCompany.incentivadorCultural" />
            </b-col>
        </b-row>
        <br>
        <Button _key="btnSaveCompany" type="success" title="Salvar" classIcon="fas fa-save" size="medium"
            :clicked="saveCompany" />
    </div>
</template>

<script>

import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "CompanyTaxation",
    components: {
        SelectStatic, Button
    },
    data() {
        return {
            urlCreate: "/api/v1/tax-document/config-company/createOrUpdate",
            urlGetById: "/api/v1/tax-document/config-company/get-by-id",
            configCompany: {
                id: "",
                source: "tributacao",
                simplesNacional: { content: 'Não', id: "false" },
                regimeTributario: { content: 'Regime Normal', id: "3" },
                regimeTributarioEspecial: { content: 'Nenhum', id: "0" },
                incentivadorCultural: { content: 'Não', id: "false" },
            },
            optionsSimplesNacional: [
                { content: 'Não', id: 'false' },
                { content: 'Sim', id: 'true' },
            ],
            optionsRegimeTributario: [
                { content: 'Simples Nacional', id: '1' },
                { content: 'Simples Nacional - Excesso de Sublimite de Receita Bruta', id: '2' },
                { content: 'Regime Normal', id: '3' },
            ],
            optionsRegimeTributarioEspecial: [
                { content: 'Nenhum', id: '0' },
                { content: 'Microempresa Municipal', id: '1' },
                { content: 'Estimativa', id: '2' },
                { content: 'Sociedade de Profissionais', id: '3' },
                { content: 'Cooperativa', id: '4' },
                { content: 'MEI - Simples Nacional', id: '5' },
                { content: 'ME EPP- Simples Nacional', id: '6' },
            ],
            optionsIncentivadorCultural: [
                { content: 'Não', id: 'false' },
                { content: 'Sim', id: 'true' },
            ]
        }
    },
    mounted() {
        this.getById();
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi", "getApi"]),
        ...mapMutations("generic", ["removeLoading"]),
        getById() {
            this.loading = true;
            let params = {
                url: this.urlGetById,
                obj: {},
            };
            this.getApi(params).then((response) => {
                if (response.success) {
                    var data = response.content;
                    if (data != null) {
                        let configCompany = {
                            id: data.id,
                            source: "tributacao",
                            simplesNacional: this.loadSelect(data.simplesNacional, "simplesNacional"),
                            regimeTributario: this.loadSelect(data.regimeTributario, "regimeTributario"),
                            regimeTributarioEspecial: this.loadSelect(data.regimeTributarioEspecial, "regimeTributarioEspecial"),
                            incentivadorCultural: this.loadSelect(data.incentivadorCultural, "incentivadorCultural"),
                        }
                        this.configCompany = configCompany;
                    }
                }
            });
        },
        saveCompany() {
            let params = { url: this.urlCreate, obj: this.configCompany };
            this.postApi(params).then(() => {
                this.removeLoading(["btnSaveCompany"]);
            });
        },
        loadSelect(obj, type) {
            if (type == "simplesNacional") return this.optionsSimplesNacional.find(x => x.id == obj.toString());
            if (type == "regimeTributario") return this.optionsRegimeTributario.find(x => x.id == obj.toString());
            if (type == "regimeTributarioEspecial") return this.optionsRegimeTributarioEspecial.find(x => x.id == obj.toString());
            if (type == "incentivadorCultural") return this.optionsIncentivadorCultural.find(x => x.id == obj.toString());
        }
    }
}

</script>
<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="btnCfopCreate" title="CFOP" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="CFOP" :width="600" :height="250" v-show="showModal('cfopCreateUpdate')">
      <CfopCreateUpdate />
    </Modal>
  </div>
</template>

<script>


import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import CfopCreateUpdate from '../../../components/modules/tax-document/cfop/CfopCreateUpdate.vue'

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CfopView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal,
    CfopCreateUpdate
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/tax-document/cfop/get-all",
        urlDeleteAllApi: "/api/v1/tax-document/cfop/delete",
        headerTable: [
          {
            field: "name",
            title: "CFOP",
            type: "link",
            iconSearch: true,
            eventName: "cfopUpdate",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("cfopCreateUpdate");
      this.addEvent({ name: "cfopCreate" });
      this.removeLoading(["btnCfopCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "cfopUpdate")
          this.openModal("cfopCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>

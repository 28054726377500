<template>
  <div>
    <Panel :module="panel.module" :title="panel.title" :showFilter="panel.showFilter" :showSearch="panel.showSearch"
      :showButtons="panel.showButtons">
      <div slot="content-main">
        <div>
          <ConfigCompany />
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import ConfigCompany from '../../../components/modules/tax-document/company/ConfigCompany.vue'

import { mapMutations } from "vuex";

export default {
  name: "ConfigTaxDocumentView",
  components: { Panel, ConfigCompany, },
  data() {
    return {
      idCertificate: "",
      panel: {
        module: "Nota Fiscal",
        title: "Configuração",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>

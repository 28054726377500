<template>
    <div>
        <br>
        <b-tabs>
            <b-tab title="Regime Tributário">
                <CompanyTaxation />
            </b-tab>
            <b-tab title="Certificado Digital">
                <Certificate v-if="!loading" />
            </b-tab>
            <b-tab title="CFOP">
                <Cfop />
            </b-tab>
            <b-tab title="Dados da Prefeitura">
                <Loading :center="true" v-show="loading" />
                <div v-if="!loading">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <InputText title="Url de Acesso" field="name" :required="true" :maxLength="500"
                                :markFormDirty="false" v-model="configCompany.urlPrefeitura" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                            <InputText title="Login" field="name" :required="true" :maxLength="100"
                                :markFormDirty="false" v-model="configCompany.loginPrefeitura" />
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                            <InputPassword title="Senha" field="number" :required="true" :maxLength="100"
                                :markFormDirty="false" v-model="configCompany.senhaPrefeitura" />
                        </b-col>
                    </b-row>
                    <br>
                    <Button _key="btnSaveCompany" type="success" title="Salvar" classIcon="fas fa-save" size="medium"
                        :clicked="saveCompany" />
                </div>
            </b-tab>
            <b-tab title="Notificação">
                <Notificacao />
            </b-tab>
            <b-tab title="Natureza da Operação">
                <NatureOperation />
            </b-tab>
            <b-tab title="Serviços">
                <Taxation />
            </b-tab>
        </b-tabs>
        <br>
    </div>
</template>

<script>


import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import Notificacao from '../notification/Notificacao.vue'
import NatureOperation from '../../../../views/modules/tax-document/NatureOperation.vue'
import Cfop from '../../../../views/modules/tax-document/Cfop.vue'
import Taxation from "../../../../views/modules/tax-document/Taxation.vue"

import Certificate from '../certificate/Certificate.vue'
import CompanyTaxation from './CompanyTaxation.vue'

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "ConfigCompany",
    components: {
        Molded,
        InputText,
        InputNumber,
        InputPassword,
        Button,
        Loading,
        Certificate,
        Taxation, NatureOperation, CompanyTaxation, Notificacao, Cfop
    },
    data() {
        return {
            loading: true,
            urlCreate: "/api/v1/tax-document/config-company/createOrUpdate",
            urlGetById: "/api/v1/tax-document/config-company/get-by-id",
            configCompany: {
                id: "",
                source: "prefeitura",
                loginPrefeitura: "",
                senhaPrefeitura: "",
            },
        }
    },
    mounted() {
        this.getById();
        this.addLoading(["panel"]);
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi", "getApi"]),
        ...mapMutations("generic", ["addLoading", "removeLoading"]),
        getById() {
            this.loading = true;
            let params = {
                url: this.urlGetById,
                obj: {},
            };
            this.getApi(params).then((response) => {
                if (response.success) {
                    let data = response.content;
                    let configCompany = {};

                    if (data == null) {
                        configCompany = {
                            id: "",
                            source: "prefeitura",
                            urlPrefeitura: "",
                            loginPrefeitura: "",
                            senhaPrefeitura: "",
                        }
                    } else {
                        configCompany = {
                            id: data.id,
                            urlPrefeitura: data.urlPrefeitura,
                            loginPrefeitura: data.loginPrefeitura,
                            senhaPrefeitura: data.senhaPrefeitura,
                        }
                    }

                    this.configCompany = configCompany;
                    this.loading = false;
                }
            });
        },
        saveCompany() {
            let params = { url: this.urlCreate, obj: this.configCompany };
            this.postApi(params).then(() => {
                this.removeLoading(["btnSaveCompany"]);
            });
        },
    },
}

</script>

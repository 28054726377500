<template>
    <div>
        <br>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                <InputText title="Nome E-mail" field="name" :maxLength="50" :markFormDirty="false"
                    v-model="configCompany.emailNome" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                <InputText title="E-mail de Resposta" field="name" :maxLength="100" :markFormDirty="false"
                    v-model="configCompany.emailResposta" />
            </b-col>
        </b-row>
        <b-row>

            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                <InputTag title="Notificar Nota Autorizada" placeholder="Digite um e-mail e pressione Enter"
                    :initialValue="configCompany.emailAutorizada" v-model="configCompany.emailAutorizada"
                    :change="saveInputTag" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                <InputTag title="Notificar Nota Rejeitada" placeholder="Digite um e-mail e pressione Enter"
                    :initialValue="configCompany.emailRejeitada" v-model="configCompany.emailRejeitada"
                    :change="saveInputTag" />
            </b-col>
        </b-row>
        <br>
        <Button _key="btnSaveCompany" type="success" title="Salvar" classIcon="fas fa-save" size="medium"
            :clicked="saveCompany" />
        <br>
    </div>
</template>

<script>

import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputTag from "@nixweb/nixloc-ui/src/component/forms/InputTag";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";


import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "Notificacao",
    components: {
        InputText,
        InputTag,
        Button,
    },
    data() {
        return {
            loading: true,
            urlCreate: "/api/v1/tax-document/config-company/createOrUpdate",
            urlGetById: "/api/v1/tax-document/config-company/get-by-id",
            configCompany: {
                id: "",
                source: "notificacao",
                emailNome: "",
                emailResposta: "",
                emailAutorizada: [],
                emailRejeitada: [],
            },
        }
    },
    mounted() {
        this.getById();
        this.addLoading(["panel"]);
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi", "getApi"]),
        ...mapMutations("generic", ["addLoading", "removeLoading"]),
        getById() {
            this.loading = true;
            let params = {
                url: this.urlGetById,
                obj: {},
            };
            this.getApi(params).then((response) => {
                if (response.success) {
                    let data = response.content;
                    let configCompany = {};

                    if (data == null) {
                        configCompany = {
                            id: "",
                            source: "notificacao",
                            emailNome: "",
                            emailResposta: "",
                            emailAutorizada: [],
                            emailRejeitada: [],
                        }
                    } else {
                        configCompany = {
                            id: data.id,
                            source: "notificacao",
                            emailNome: data.emailNome,
                            emailResposta: data.emailResposta,
                            emailAutorizada: data.emailAutorizada ? data.emailAutorizada : [],
                            emailRejeitada: data.emailRejeitada ? data.emailRejeitada : [],
                        }
                    }
                    this.configCompany = configCompany;
                    this.loading = false;
                }
            });
        },
        saveInputTag() {
            if (this.id) this.saveCompany();
        },
        saveCompany() {
            let params = { url: this.urlCreate, obj: this.configCompany };
            this.postApi(params).then(() => {
                this.removeLoading(["btnSaveCompany"]);
            });
        },
    },
}

</script>

<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="btnTaxationCreate" title="Serviço" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Serviço" :width="800" :height="250" v-show="showModal('taxationCreateUpdate')">
      <TaxationCreateUpdate />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import TaxationCreateUpdate from '../../../components/modules/tax-document/taxation/TaxationCreateUpdate.vue'

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TaxationView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal,
    TaxationCreateUpdate
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/tax-document/taxation/get-all",
        urlDeleteAllApi: "/api/v1/tax-document/taxation/delete",
        headerTable: [
          {
            field: "identificacao",
            title: "Identificação",
            type: "link",
            iconSearch: true,
            eventName: "taxationUpdate",
          },
          {
            field: "codigo",
            title: "Código (LC116)",
            type: "text",
          },
          {
            field: "codigoTributacao",
            title: "Código Tributação",
            type: "text",
          },
          {
            field: "cnae",
            title: "CNAE",
            type: "text",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("taxationCreateUpdate");
      this.addEvent({ name: "taxationCreate" });
      this.removeLoading(["btnTaxationCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "taxationCreate" || event.name == "taxationUpdate") this.openModal("taxationCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>

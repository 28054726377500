<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText title="Identificação Interna" field="identificacao" :formName="formName" :required="true"
          :maxLength="100" v-model="taxation.identificacao" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <InputText title="Código (LC116)" field="codigo" :formName="formName" :maxLength="50" v-model="taxation.codigo"
          :markFormDirty="false" />
      </b-col>
      <b-col sm="4">
        <InputText title="Código Tributação" field="codigoTributacao" :formName="formName" :required="false"
          :maxLength="50" v-model="taxation.codigoTributacao" :markFormDirty="false" />
      </b-col>
      <b-col sm="4">
        <InputText title="CNAE" field="cnae" :formName="formName" :required="false" :maxLength="50"
          v-model="taxation.cnae" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="9">
        <SelectStatic title="Tipo de Tributação ISS" :formName="formName" :required="true"
          :initialValue="taxation.tipoTributacao" :data="optionsTipoTributacao" v-model="taxation.tipoTributacao" />
      </b-col>
      <b-col sm="3">
        <InputNumber title="Alíquota ISS" field="aliquota" type="float" :formName="formName" :required="true"
          :maxLength="9" v-model="taxation.aliquota" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <TextArea title="Descrição do Serviço (Padrão)" field="descricao" :formName="formName" :required="true"
          :maxLength="2000" v-model="taxation.discriminacao" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSaveTypeRental" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveTypeRental" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";

import Taxation from "@/components/modules/tax-document/taxation/Taxation.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "TaxationCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { InputText, InputNumber, Button, SelectStatic, TextArea },
  data() {
    return {
      formName: "taxationCreateUpdate",
      taxation: new Taxation(),
      urlCreate: "/api/v1/tax-document/taxation/create",
      urlUpdate: "/api/v1/tax-document/taxation/update",
      optionsTipoTributacao: [
        { content: 'Tributação no Município', id: '1' },
        { content: 'Tributação fora do Município', id: '2' },
        { content: 'Isenção', id: '3' },
        { content: 'Imune', id: '4' },
        { content: 'Exigibilidade Suspensa por Decisão Judicial', id: '5' },
        { content: 'Exigibilidade Suspensa por Procedimento Administrativo', id: '6' },
      ],
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveTypeRental() {
      if (this.taxation.id) {
        let params = { url: this.urlUpdate, obj: this.taxation };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["btnSaveTypeRental"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.taxation };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);;
          }
          this.removeLoading(["btnSaveTypeRental"]);
        });
      }
    },
    update(data) {
      data.tipoTributacao = this.optionsTipoTributacao.find(x => x.id == data.tipoTributacao.id);

      this.taxation.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "taxationCreate") {
          this.resetValidation(this.formName);
          this.taxation = new Taxation();
        }
        if (event.name == "taxationUpdate") {
          this.update(event.data);
        }
      },
      deep: true,
    },
  },
};
</script>

<template>
    <div>
        <div v-if="loading">
            <span>Aguarde, estamos verificando informações...</span>
            <Loading type="line" :center="false" />
        </div>
        <div v-else>
            <div v-if="!certificate.id">
                <Alert type="warning">
                    <span>
                        <b>Certificado Digital</b>, clique abaixo para adicionar.
                    </span>
                </Alert>
                <Button _key="btnloadCertificate" type="primary" title="Certificado" classIcon="fa-solid fa-circle-plus"
                    size="small" :clicked="loadCertificate" />
            </div>
            <div v-else>
                <Alert type="success">
                    <span>
                        {{ certificado.nome }}.
                    </span>
                </Alert>
                <Button _key="btnRemoveCertificate" type="danger" title="Remover" classIcon="fa-solid fa-trash" size="small"
                    :clicked="removeCertificate" />
            </div>
        </div>
        <Modal title="Certificado Digital" :width="650" v-if="showModal('certificate')">
            <div v-if="loading">
                <span>Aguarde, estamos carregando os dados, isso pode levar alguns segundos...</span>
                <Loading type="line" :center="false" />
            </div>
            <div v-else>
                <div>
                    <Alert type="info">
                        <span>
                            Selecione o certificado no formato
                            <b>.pfx </b>.
                        </span>
                        <br />
                    </Alert>
                    <FileUpload title="Carregar .pfx" classIcon="fa-solid fa-file-export" container="nixloc-certificate"
                        accepted=".pfx" allowed=".pfx" :disabled="true" urlPost="/api/v1/adm/file-upload/upload"
                        urlRemove="/api/v1/adm/file-upload/delete" :nameDataBase="certificate.file"
                        v-model="certificate.file" />
                </div>
                <b-row>
                    <b-col sm="6">
                        <InputPassword title="Senha" field="number" :required="true" :maxLength="100"
                            v-model="certificate.password" :markFormDirty="false" />
                    </b-col>
                    <b-col sm="6">
                        <InputText title="E-mail de Notificação" field="name" :required="true" :maxLength="100"
                            v-model="certificate.email" :markFormDirty="false" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <div class="text-right">
                            <Button _key="btnSaveCertificate" :disabled="disabled" type="success" title="Salvar"
                                classIcon="fas fa-save" size="medium" :clicked="saveCertificate" />
                        </div>
                    </b-col>
                </b-row>
            </div>
        </Modal>
    </div>
</template>

<script>

import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import FileUpload from "@nixweb/nixloc-ui/src/component/forms/FileUpload";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "Certificate",
    components: {
        Alert, FileUpload, InputText, InputPassword, Button, Loading, Modal
    },
    props: ["value"],
    data() {
        return {
            certificate: {
                id: "",
                file: "",
                login: "",
                password: "",
                email: "",
            },
            certificado: {},
            urlCreate: "/api/v1/tax-document/certificate/create",
            urlGetIdCertificate: "/api/v1/tax-document/certificate/get-id-certificate",
            urlGetConsultaCertificado: "/api/v1/tax-document/certificate/consulta-certificado",
            urlRemoveCertificado: "/api/v1/tax-document/certificate/remove-certificado",
            loading: true,
        }
    },
    mounted() {
        this.getIdCertificate();
    },
    computed: {
        ...mapGetters("generic", ["showModal"]),
        disabled() {
            if (!this.certificate.file) return true;
            if (!this.certificate.password) return true;
            if (!this.certificate.email) return true;
            return false;
        }
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi", "getApi", "deleteAllApi"]),
        ...mapMutations("generic", ["addEvent", "removeLoading", "openModal", "hideModal"]),
        loadCertificate() {
            this.removeLoading(["btnloadCertificate"]);
            this.openModal("certificate");
        },
        removeCertificate() {
            let params = {
                url: this.urlRemoveCertificado,
                selected: [],
            };
            this.deleteAllApi(params).then((reponse) => {
                if (reponse.success) {
                    this.certificate.id = "";
                    this.$emit("input", this.certificate.id);
                    this.getConsultaPrefeitura();
                }
                this.removeLoading(["btnRemoveCertificate"]);
            });
        },
        getIdCertificate() {
            let params = {
                url: this.urlGetIdCertificate,
                obj: {},
            };
            this.getApi(params).then((response) => {
                if (response.success) {
                    if (response.content) {
                        this.certificate.id = response.content.idCertificado;
                        this.getConsultaCertificado();
                    }
                    this.loading = false;
                }
            });
        },
        getConsultaCertificado() {
            let params = {
                url: this.urlGetConsultaCertificado,
                obj: { idCertificado: this.certificate.id },
            };
            this.getApi(params).then((response) => {
                this.certificado = response.content;
                var vencimento = new Date(this.certificado.vencimento);

                var day = addZero(vencimento.getDate());
                var month = addZero(vencimento.getMonth() + 1); // Mês começa do zero
                var year = vencimento.getFullYear();
                this.certificado.vencimento = day + "/" + month + "/" + year;

                function addZero(number) {
                    if (number < 10) {
                        return "0" + number;
                    }
                    return number;
                }

                this.loading = false;
            });
        },
        saveCertificate() {
            let params = {
                url: this.urlCreate,
                obj: this.certificate,
                notNotifyToast: true,
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.certificate.id = response.content.data.id;
                    this.hideModal();
                    this.getIdCertificate();
                }
                this.removeLoading(["btnSaveCertificate"]);
            });
        }
    }
}
</script>